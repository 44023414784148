<template>
  <b-card>
    <form-component :job-offer="jobOffer" @onSubmit="onSubmit" @fetchByLocale="fetchJobOffer" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/job-offers/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/job-offers';
import { convertFromURLToFile } from '@/constants/utils/file';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      jobOffer: {
        title: '',
        title_image: null,
        description: '',
        requirements: [],
        benefits: [],
        nice_to_have: [],
        responsibilities: [],
        rate: null,
        working_time: null,
        site_images: [null, null],
        city: null,
        tag: null,
      },
    };
  },

  async mounted() {
    await this.fetchJobOffer();
  },

  methods: {
    mapOfferArray(array) {
      if (!array) return [];

      return array.map((item) => {
        return {
          id: Math.random(),
          value: item,
        };
      });
    },
    appendOfferArray(formData, array, key) {
      for (let i = 0; i < array.length; i++) {
        formData.append(`${key}[${i}]`, array[i].value);
      }
    },
    async fetchJobOffer(lang = 'pl') {
      try {
        const { data } = await show(this.$route.params.id, { lang });

        data.data.responsibilities = this.mapOfferArray(data.data.responsibilities);
        data.data.benefits = this.mapOfferArray(data.data.benefits);
        data.data.requirements = this.mapOfferArray(data.data.requirements);
        data.data.nice_to_have = this.mapOfferArray(data.data.nice_to_have);

        this.jobOffer = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił błąd podczas pobierania ofert pracy. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    async onSubmit(form) {
      const formData = new FormData();

      formData.append('title', form.title);
      formData.append('lang', form.lang);
      formData.append('description', form.description);
      formData.append('rate', form.rate);
      formData.append('city', form.city);
      formData.append('working_time', form.working_time);
      formData.append('seo_description', form.seo_description);
      formData.append('tag', form.tag);

      formData.append('responsibilities', JSON.stringify(form.responsibilities.map((item) => item.value)));
      formData.append('benefits', JSON.stringify(form.benefits.map((item) => item.value)));
      formData.append('requirements', JSON.stringify(form.requirements.map((item) => item.value)));
      formData.append('nice_to_have', JSON.stringify(form.nice_to_have.map((item) => item.value)));

      if (form.site_images[0]) {
        formData.append('site_images[0]', form.site_images[0].file, form.site_images[0].file.fileName);
      }

      if (form.site_images[1]) {
        formData.append('site_images[1]', form.site_images[1].file, form.site_images[1].file.fileName);
      }

      try {
        await update(this.jobOffer.id, formData);

        this.showSuccessNotification('Dane zapisane', 'Oferta pracy została zapisana.');
        this.$router.push({ name: 'job-offers-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas edycji oferty pracy. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
